import { getUserRole, Role } from '@/auth/utils'
import management from './management'
import personel from './personel'

export default () => {
  if (getUserRole() === Role.Admin) {
    return [...management]
  }  
  
  if (getUserRole() === Role.TraitsAndHealth) {
    return [...personel]
  }
  
  return [];
};
