export default [
  {
    title: 'Dashboard',
    route: 'manage-homebase',
    icon: 'ActivityIcon',
  },

  /* Account */
  {
    header: 'Customer Management ',
  },

  {
    title: 'Customer Tickets',
    icon: 'MessageSquareIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Open Tickets',
        route: 'manage-ticket-open',
      },
      {
        title: 'Closed Tickets',
        route: 'manage-ticket-closed',
      },
      {
        title: '',
        route: 'manage-ticket-detail',
        hidden: true,
      },
    ],
  },

  /* Trait Data */
  {
    header: 'Trait',
  },
  {
    title: 'Trait Datas',
    icon: 'LayoutIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Trait Category',
        route: 'manage-trait-category',
      },
      {
        title: 'trait category',
        route: 'manage-trait-category-save',
        hidden: true,
      },

      {
        title: 'Trait Genotype',
        route: 'manage-trait-genotype',
      },
      {
        title: 'trait genotype',
        route: 'manage-trait-genotype-save',
        hidden: true,
      },

      {
        title: 'Trait Marker',
        route: 'manage-trait-marker',
      },
      {
        title: 'trait marker',
        route: 'manage-trait-marker-save',
        hidden: true,
      },

      {
        title: 'Trait Feature',
        route: 'manage-trait-feature',
      },
      {
        title: 'trait feature',
        route: 'manage-trait-feature-save',
        hidden: true,
      },

      {
        title: 'Trait Effect',
        route: 'manage-trait-effect',
      },
      {
        title: 'trait effect',
        route: 'manage-trait-effect-save',
        hidden: true,
      },
    ],
  },
  {
    title: 'Traits',
    route: 'manage-traits',
    icon: 'ActivityIcon',
  },
  {
    title: 'Trait Frequency',
    route: 'manage-trait-frequency',
    icon: 'ActivityIcon',
  },
]
