<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      © illustrative dna. {{ new Date().getFullYear() }}. All Rights Reserved.
    </span>

    <span class="float-md-right d-none d-md-block">Developed By illustrative soft
      <feather-icon
        icon="CodeIcon"
        size="21"
        class="text-danger stroke-current"
      />
    </span>
  </p>
</template>

<script>
export default {
  components: {
  },
}
</script>
